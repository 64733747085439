import React from 'react'
import Documentt from './Documentt'

export default function CV() {

    return (
        <div >
            <div className="flex flex-col items-center gap-4 justify-center w-full" >

                <Documentt />

            </div>
        </div>

    )
}
