import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import {
  EnvelopeIcon,
  LinkIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";

export default function Documentt() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [experience, setExperience] = useState([]);
  const [works, setWorks] = useState([]);
  const [contact, setContact] = useState({});
  const [workLoading, setWorkLoading] = useState(true);
  const [experienceLoading, setExperienceLoading] = useState(true);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [skillsLoading, setSkillsLoading] = useState(true);
  const [contactLoading, setContactLoading] = useState(true);
  const [summary, setSummary] = useState([]);

  useEffect(() => {
    function getSummary() {
      axios.get(`/get-about/`).then((res) => {
        setSummary(res.data);
        setSummaryLoading(false);
      });
    }

    getSummary();
  }, []);

  const [notShownExperiences, setNotShownExperiences] = useState([]);

  // This method fetches the records from the database.
  useEffect(() => {
    function getExperience() {
      axios.get(`/get-all-experience/`).then((res) => {
        if (!res.data) {
          const message = `An error occured: ${res.statusdiv}`;
          window.alert(message);
          return;
        }
        setExperience(res.data);
        const array = res.data.filter((experience) => !experience.showInCV);
        setNotShownExperiences(array);
        setExperienceLoading(false);
      });
    }

    getExperience();

    return;
  }, [experience.length]);

  useEffect(() => {
    function getContact() {
      axios.get(`/get-contact/`).then((res) => {
        if (!res.data) {
          const message = `An error occured: ${res.statusdiv}`;
          window.alert(message);
          return;
        }
        setContact(res.data);
        setContactLoading(false);
      });
    }

    getContact();

    return;
  }, [experience.length]);

  const [notShownWorks, setNotShownWorks] = useState([]);

  useEffect(() => {
    async function getWorks() {
      axios.get(`/get-all-work/`).then((res) => {
        if (!res.data) {
          const message = `An error occured: ${res.statusdiv}`;
          window.alert(message);
          return;
        }
        setWorks(res.data);
        const array = res.data.filter((work) => !work.showInCV);
        setNotShownWorks(array);
        setWorkLoading(false);
      });
    }

    getWorks();

    return;
  }, [works.length]);

  const [skills, setSkills] = useState([]);
  useEffect(() => {
    async function getSkills() {
      axios.get("/get-types-with-skills").then((res) => {
        if (!res.status === 200) {
          const message = `An error occured: ${res.statusdiv}`;
          window.alert(message);
          return;
        }

        setSkills(res.data);
        setSkillsLoading(false);
      });
    }

    getSkills();

    return;
  }, [skills.length]);

  function SkillsList() {
    return skills.map((type, i) => {
      return (
        <div key={i} className="mb-2">
          {type._id == "Skills" ? (
            <div className={`${subTitle}`}>Tech Skills</div>
          ) : (
            <div className={`${subTitle}`}>{type._id}</div>
          )}
          {type.records.map((skill) => {
            return (
              <div className={`${content}`} key={skill._id}>
                {skill.name}
              </div>
            );
          })}
        </div>
      );
    });
  }

  function ExperienceList() {
    return experience.map((experience) => {
      return (
        <div key={experience._id} className="mb-2">
          <div className={`${subTitle}`}>{experience.name}</div>
          <div className={`${content}`}>{experience.position}</div>
          <div className={`${content}`}>{experience.date}</div>
          <div className={`${content}`}>{experience.description}</div>
        </div>
      );
    });
  }

  function WorksList() {
    return works.map((work) => {
      return (
        <div key={work._id} className="mb-2">
          <div className={`${subTitle}`}>{work.name}</div>
          <div className={`${content}`}>{work.description}</div>
          <div className={`${content}`}>{work.technologies}</div>
          <div className={`${content}`}>{work.link}</div>
        </div>
      );
    });
  }

  const title = "font-extrabold mb-2 text-[#f1924fff] my-2";
  const subTitle = "text-sm font-bold text-[#387b9cff]";
  const content = "text-sm";
  const icon = "h-4 w-4 text-[#f1924fff]";

  if (experienceLoading || workLoading || contactLoading || skillsLoading) {
    return <div>loading</div>;
  }

  return (
    <>
      <button
        className="border border-black text-black hover:bg-black font-bold transition duration-200 hover:text-white px-4 py-2 rounded-lg"
        onClick={handlePrint}
      >
        DOWNLOAD CV
      </button>

      <div className="print-source">
        <div
          ref={componentRef}
          style={{
            height: "100%",
            width: "100%",
          }}
          className="flex gap-2 p-[25px]"
        >
          <section className="" style={{ flex: 3 }}>
            <div>
              <div className="mb-4">
                <div className="font-extrabold text-5xl capitalize text-[#f1924fff]">
                  {contact?.name + " " + contact?.lastname}
                </div>
                <div className={`text-3xl text-[#387b9cff]`}>
                  {contact.title}
                </div>
              </div>
              <div>
                <div className={`${content} text-justify`}>
                  {summary[0].content}
                </div>
              </div>
              <div className="grid grid-cols-12 mt-2 gap-2">
                <div className={`text-sm col-span-4 flex items-center gap-2`}>
                  <PhoneIcon className={`${icon}`} />
                  {contact.phone}
                </div>
                <div className={`text-sm col-span-8 flex items-center gap-2`}>
                  <EnvelopeIcon className={`${icon}`} /> {contact.email}
                </div>
                <div className={`text-sm col-span-4 flex items-center gap-2`}>
                  <MapPinIcon className={`${icon}`} />
                  {contact.location}
                </div>
                <div className={`text-xs col-span-8 flex items-center gap-2 `}>
                  <LinkIcon className={`${icon}`} />
                  <div className="break-all">
                    linkedin.com/in/souissi-rahma-60a662218
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className={`${title}`}>Expérience Professionnelle</div>
              {ExperienceList()}
            </div>
          </section>

          <section style={{ flex: 2 }} className="p-4">
            <div className="flex justify-center">
              <img
                src="/img/rahma.jpg"
                alt="me"
                className="rounded h-[200px] rounded-bl-[50px] rounded-tr-[50px]"
              />
            </div>

            <div>
              <div className={`${title}`}>Éducation</div>
              <div className={`${subTitle}`}>Éducation et Enseignement</div>
              <div className={`${content}`}>
                Institut Supérieur d'Études Appliquées en Humanités, Mahdia.
              </div>
              <div className={`${content}`}>septembre 2021 - Aujourd'hui</div>
              <div className={`${content}`}>Sfax, Tunisia</div>
            </div>

            <div>
              <div className={`${title}`}>Compétences</div>
              {SkillsList()}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
